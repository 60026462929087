import React, { useState } from "react";
import { Grid2, Typography } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import MyContainer from "../MyContainer";
import {sendEmail} from "../../services/api";

const ContactForm = () => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [formData, setFormData] = useState({
    email: "",
    nombre: "",
    tipo: "",
    mensaje: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents default form submission behavior

    try {
      const captchaToken = await executeRecaptcha("submit");

      const {data,response} = await sendEmail({...formData, captchaToken});
      console.log("##############################");
      console.log("Full Response:", response);
      console.log("##############################");
      console.log("Data:", data);
      console.log("##############################");
      
      //console.log(data);
      

      if ((response && response.status === 200) || (data && data.status === 'Email sent successfully')) {
        alert("Revisa tu correo, recibiste un mail de confirmacion!");
      } else {
        alert("Por alguna razon fallo el envio del mail, intenta mas tarde porfavor.");
      }
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code outside the 2xx range
        console.error("Server responded with an error:", error.response.data);
        console.error("Status code:", error.response.status);

        // Log the full request details
        console.error("Request method:", error.config.method.toUpperCase());
        console.error("Request URL:", error.config.url);
        console.error("Request headers:", error.config.headers);
        console.error("Request data (body):", error.config.data);

        alert(
          `Error: ${
            error.response.data.error || "An error occurred on the server"
          }`
        );
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received from the server:", error.request);

        // Log the full request details
        console.error("Request method:", error.config.method.toUpperCase());
        console.error("Request URL:", error.config.url);
        console.error("Request headers:", error.config.headers);
        console.error("Request data (body):", error.config.data);

        alert("No response from the server. Please try again later.");
      } else {
        // Something else happened in setting up the request
        console.error("Error setting up the request:", error.message);

        // Log the full request details if available
        if (error.config) {
          console.error("Request method:", error.config.method.toUpperCase());
          console.error("Request URL:", error.config.url);
          console.error("Request headers:", error.config.headers);
          console.error("Request data (body):", error.config.data);
        }

        alert(`An error occurred: ${error.message}`);
      }
    }
  };

  return (
    <MyContainer>
      <Grid2 item size={{ lg: 12 }}>
        <section className="contact" id="contact">
          <Typography variant="h2"> Mantengámonos en contacto</Typography>
          <p>¡Pronto habrá más para ti!</p>
          <p className="subtitle">
            Inscríbete y sé el primero en conocer nuestras novedades
          </p>

          <form className="form-grid" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Correo</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="tipo">Yo soy...</label>
              <select
                id="tipo"
                name="tipo"
                value={formData.tipo}
                onChange={handleChange}
              >
                <option value="">Selecciona una opción</option>
                <option value="paciente">Paciente</option>
                <option value="terapeuta">Terapeuta</option>
                <option value="otro">Otro</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="mensaje">Quiero decir...</label>
              <textarea
                id="mensaje"
                name="mensaje"
                value={formData.mensaje}
                onChange={handleChange}
                style={{ width: "100%", height: "120px" }}
              ></textarea>
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Enviar
              </button>
            </div>
          </form>
        </section>
      </Grid2>
    </MyContainer>
  );
};


export default ContactForm;
