import React from "react";

import { Grid2, Typography } from "@mui/material";

import Box from "@mui/material/Box";

const bordeLayout = 0;


const Center = () => {
  return (
    
    <Grid2 container paddingTop={4} border={0}>
      <Grid2
        item
        size={{ lg: 6 }}
        border={bordeLayout}
        display={"flex"}
        alignItems={"center"}>
        <Box display={"flex"} border={0} justifyContent={"center"} flexDirection={"column"}>
          <Typography variant="h3" fontWeight={"bold"} paddingBottom={4} textAlign={"center"}>
            Encuentra tu terapia personalizada con <span>IA</span>
          </Typography>
          <Box border={0} display={"flex"} justifyContent={"center"}>
          <div className="buttons">
            <button className="btn btn-primary">IArtemisa</button>
            <button className="btn btn-secondary">
              <a href="#contact" style={{ textDecoration: "none", color: "black" }}>
                Reserva tu cupo
              </a>
            </button>
          </div>
          </Box>
        </Box>
      </Grid2>
      <Grid2
        item
        size={{ lg: 6 , sm : 12 }}
        border={0}
        sx={{ display: { xs: "none", sm: "flex" } }}
        justifyContent={"center"}>
        <div className="hero-content">
          <div className="hero-image">
            <img
              className="octa"
              src="/ia_futuristic_teraphy.jpg"
              width={500}
              height={400}
              alt="IA Therapy Interface"
            />
          </div>
        </div>
      </Grid2>
    </Grid2>
  );
};

export default Center;
