import React from "react";
import UserData from "../user_data/UserData";
import LoadingScreen from "../Loading";
import { useTheme, Grid2 } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isLoading } = useAuth0();
  const theme = useTheme();

  return (
    <>
          <Grid2 container border={0} sx={{p : 1 }} display={"flex"} justifyContent={"center"} >
        {user && <UserData user={user} />}
      </Grid2>
    </>
  );
};

export default Profile;
