import { useEffect, useState } from "react";
import { Grid2, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {Home,Chat,Feedback,Star,AccountCircle,ExitToApp} from "@mui/icons-material";
import { styled, useTheme } from '@mui/material/styles';
import TopMenu from "../auth/TopMenu";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from "@mui/material/Box";
import Login from "../auth/Login";
import {useMediaQuery} from "@mui/material";


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SideBar2 = ( props ) => {

    const { isAuthenticated,logout  } = useAuth0();
    const theme = useTheme();
    const { open , setOpen } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const MyLink = (props) => {
      return <Link to={props.to} style={{ textDecoration: "none" }}>{props.children}</Link>
    }
    useEffect ( () => {
        setOpen ( isAuthenticated && !isMobile);
    },[isAuthenticated, isMobile]); 

    return <>
        <AppBar position="sticky" open={open} elevation={0} >
          <Toolbar>
             <Box display={ isAuthenticated ? 'inline' : 'none'} >
               <IconButton
                 color="primary"
                 aria-label="open drawer"
                 onClick={handleDrawerOpen}
                 edge="start"
                 sx={[{mr: 2,}, open && { display: 'none' } ]}>
                 <MenuIcon sx={{color : "black"}}/>
               </IconButton>
             </Box>
             <a href="/">
                <img width={60} height={60} src="/icono_wm.png" />
             </a>
             <Typography variant="h6" noWrap component="div">
                White Mirror
             </Typography>
             <Box display={"flex"} justifyContent={"flex-end"} sx={{flexGrow : 1}} >
                <Box border={0}>
                {
                  isAuthenticated ? 
                  <Box display={"flex"} alignItems={"center"}>
                      <TopMenu />
                  </Box>
                  :
                  <Login/>
                }
                </Box>
             </Box>
           </Toolbar>
         </AppBar>
         <Drawer  sx={{
                   width: drawerWidth,
                   flexShrink: 0,
                   '& .MuiDrawer-paper': {
                     width: drawerWidth,
                     boxSizing: 'border-box',
                     background: theme.palette.primary.main
                   },
                 }}
                variant="persistent"
                anchor="left" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        
        <List>
        <MyLink to="/dashboard" >
          <ListItem>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText ><Typography color="secondary">Inicio</Typography></ListItemText>
          </ListItem>
        </MyLink>
        <MyLink to="/profile">
          <ListItem>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Datos Personales" />
          </ListItem>
        </MyLink>
        <MyLink to="/feedback" >
          <ListItem>
            <ListItemIcon>
              <Feedback />
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItem>
        </MyLink>
        <MyLink to="/premium">
          <ListItem>
            <ListItemIcon>
              <Star />
            </ListItemIcon>
            <ListItemText primary="Obtener Premium" />
          </ListItem>
        </MyLink>
        <MyLink to="/ChatbotAudioText">
          <ListItem>
            <ListItemIcon>
              <Chat />
            </ListItemIcon>
            <ListItemText primary="ChatbotAudioText" />
          </ListItem>
        </MyLink>
        <MyLink to="/chatbottext">
          <ListItem>
            <ListItemIcon>
              <Chat />
            </ListItemIcon>
            <ListItemText primary="Chat-Bot texto" />
          </ListItem>
        </MyLink>
      </List>

      <Divider />

      <List>
        <MyLink to="/chatbottext">
          <ListItem>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Terminar Sesión" onClick={ () => logout() }/>
          </ListItem>
        </MyLink>        
      </List>
      </Drawer>
      
  </>
};


export default SideBar2;


