import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getUser, sendFeedbackEmail } from "../services/api";
import { TextField, Grid, Button, Typography } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Feedback = () => {
  const { user, isLoading } = useAuth0(); // Auth0 user info
  const [userInfo, setUserInfo] = useState(null);

  // reCAPTCHA v3
  const { executeRecaptcha } = useGoogleReCaptcha();

  // State for feedback form
  const [title, setTitle] = useState("");     // Former 'subject'
  const [explanation, setExplanation] = useState("");  // Former 'feedback'

  // Fetch user data via getUser
  useEffect(() => {
    if (user && user.email) {
      (async () => {
        try {
          const response = await getUser(user.email);
          console.log("User data fetched:", response.data);
          setUserInfo(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      })();
    }
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Build full name if user info is present
  const getFullName = userInfo
    ? `${userInfo.nombres.value} ${userInfo.apellidos.value}`
    : "";

  // Handlers
  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleExplanationChange = (e) => setExplanation(e.target.value);

  // Handle feedback submit
  const handleSubmit = async () => {
    if (!executeRecaptcha) {
      console.warn("reCAPTCHA has not been loaded");
      return;
    }

    try {
      // Execute reCAPTCHA
      const captchaToken = await executeRecaptcha("submit");
      console.log("reCAPTCHA token:", captchaToken);

      // Construct subject and body based on user input
      const finalSubject = `Feedback: ${title}`;
      const finalBody = `${explanation}`;

      // Send feedback email
      await sendFeedbackEmail({
        email: user.email,
        nombre: getFullName,  // If your backend uses 'nombre' directly
        subject: finalSubject,
        feedback: finalBody,
        captchaToken,         // reCAPTCHA token
      });

      alert("Feedback Enviado, revisa tu email!");
    } catch (error) {
      console.error("Error sending feedback:", error);
      alert("Error sending feedback. Check console for details.");
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      style={{ minHeight: "100vh", paddingTop: "50px" }}
    >
      <Grid item>
        <Typography variant="h6" gutterBottom>
          Estimad@ {getFullName}, ¿cuál es su feedback?
        </Typography>
      </Grid>

      <Grid item container spacing={2} direction="column" alignItems="center">
        <Grid item>
          {/* Título de tu feedback -> combined into subject as 'Feedback: ...' */}
          <TextField
            fullWidth
            size="medium"
            label="Título de tu feedback"
            value={title}
            onChange={handleTitleChange}
            variant="outlined"
            sx={{
              width: "400px",
              height: "50px",
            }}
          />
        </Grid>
        <Grid item>
          {/* Explanation -> Body of the email */}
          <TextField
            fullWidth
            multiline
            rows={6}
            size="medium"
            label="Explícanos el feedback"
            value={explanation}
            onChange={handleExplanationChange}
            variant="outlined"
            sx={{
              width: "400px",
              height: "200px",
            }}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Enviar Feedback
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Feedback;
