import React, { useState } from "react";
import { Box, Button, TextField, Typography, CircularProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ChatBar from "./ChatBar";
import { AudioRecorder } from "react-audio-voice-recorder";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useAuth0 } from "@auth0/auth0-react";
import { sendFormDataForQueryV2 } from "../services/api";

// OPTIONAL: If you want to play the AI's response audio:
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const ChatbotAudioText = () => {
  // We’ll store chat messages like { type: "User"|"IA", text: string }
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false); // For spinner
  const [mode, setMode] = useState("text"); // "text" or "audio"
  const [textInput, setTextInput] = useState(""); // For user text input
  const recorderControls = useAudioRecorder();

  const { user } = useAuth0();

  // We'll also keep track of the latest AI audio URL to play it
  const [aiAudioUrl, setAiAudioUrl] = useState("");

  // Toggle between "text" mode and "audio" mode
  const handleModeChange = (event, newMode) => {
    if (newMode) setMode(newMode);
  };

  // 1) If user is in TEXT mode and clicks "Send"
  const handleSendText = async () => {
    if (!textInput.trim()) return;
    setLoading(true);

    // Save the user's text to the chat
    const userMessage = { type: "User", text: textInput.trim() };
    setMessages((prev) => [...prev, userMessage]);

    // Build form data
    const formData = new FormData();
    formData.append("email", user?.email || "user@notdefined.cl");
    formData.append("user_text", textInput.trim());

    try {
      const jsonData = await sendFormDataForQueryV2(formData);
      // jsonData should contain { user_message_text, assistant_message_text, assistant_audio_url }

      // Save the IA message
      const iaMessage = {
        type: "IA",
        text: jsonData.assistant_message_text,
      };
      setMessages((prev) => [...prev, iaMessage]);

      // Optionally, store the AI audio URL to play it
      setAiAudioUrl(jsonData.assistant_audio_url);
    } catch (error) {
      console.error("Error sending text message:", error);
    } finally {
      setTextInput("");
      setLoading(false);
    }
  };

  // 2) If user is in AUDIO mode and finishes recording
  const handleAudioComplete = async (audioBlob) => {
    setLoading(true);

    // Add a placeholder user message
    // (We could store "Audio recorded" or wait until transcription returns)
    const userMessage = { type: "User", text: "[Audio Message]" };
    setMessages((prev) => [...prev, userMessage]);

    // Build form data
    const formData = new FormData();
    formData.append("email", user?.email || "user@notdefined.cl");
    formData.append("file", audioBlob);

    try {
      const jsonData = await sendFormDataForQueryV2(formData);
      // The user_message_text from the server is the transcribed audio
      // We can update the last user message with the actual transcription
      const transcription = jsonData.user_message_text || "[Transcribed audio]";

      // Replace the placeholder with the actual transcription
      setMessages((prevMessages) => {
        const updated = [...prevMessages];
        // The last entry is the user audio placeholder
        updated[updated.length - 1] = { type: "User", text: transcription };
        return updated;
      });

      // Now add the IA's response
      const iaMessage = {
        type: "IA",
        text: jsonData.assistant_message_text,
      };
      setMessages((prev) => [...prev, iaMessage]);

      // Store the AI audio URL if you want to play it
      setAiAudioUrl(jsonData.assistant_audio_url);
    } catch (error) {
      console.error("Error sending audio message:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="row" minHeight="100vh" bgcolor="#f5f5f5">
      {/* Main Chat Content */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        width="75%" 
        padding="16px"
        sx={{ paddingTop: "10vh" }}
      >
        <Typography variant="h5" gutterBottom>
          Chatbot (Text or Audio)
        </Typography>

        {/* MODE TOGGLE */}
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={handleModeChange}
          sx={{ marginBottom: 2 }}
        >
          <ToggleButton value="text">Text Mode</ToggleButton>
          <ToggleButton value="audio">Audio Mode</ToggleButton>
        </ToggleButtonGroup>

        {/* TEXT MODE UI */}
        {mode === "text" && (
          <Box display="flex" flexDirection="column" alignItems="center" width="100%" maxWidth={400}>
            <TextField
              fullWidth
              label="Escribe tu mensaje"
              variant="outlined"
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleSendText}>
              Enviar
            </Button>
          </Box>
        )}

        {/* AUDIO MODE UI */}
        {mode === "audio" && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <AudioRecorder
              onRecordingComplete={(blob) => handleAudioComplete(blob)}
              recorderControls={recorderControls}
              downloadOnSavePress={false}
              downloadFileExtension="mp4"
            />
            <Typography sx={{ marginTop: 1 }}>Graba tu mensaje y envíalo.</Typography>
          </Box>
        )}

        {/* LOADING SPINNER */}
        {loading && (
          <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
            <CircularProgress size={40} />
            <Typography variant="body1" marginTop={1}>Pensando...</Typography>
          </Box>
        )}

        {/* OPTIONAL: Play AI's Audio Response */}
        {aiAudioUrl && (
          <Box marginTop={3} width="100%" maxWidth={400}>
            <Typography variant="subtitle1">Respuesta de IA (Audio):</Typography>
            <AudioPlayer autoPlay src={`${process.env.REACT_APP_API_URL}${aiAudioUrl}`} />
            {/* Notice we combine the base URL + the returned audio_url, e.g. "/api/v2/audio/..." */}
          </Box>
        )}
      </Box>

      {/* SIDEBAR: Chat History */}
      <ChatBar messages={messages} />
    </Box>
  );
};

export default ChatbotAudioText;
