import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useEffect, useState } from "react";
import { Auth0Provider } from '@auth0/auth0-react';
import Landing from './components/landing/Landing';
import ConversationalChat from './components/conversational-chat/ConversationalChat.jsx';
import Profile from './components/pantalla_inicial/Profile';
import Feedback from './components/pantalla_inicial/Feedback';
import Premium from './components/pantalla_inicial/Premium';
import CompleteInformation from './components/auth/CompleteInformation.jsx';
import ChatbotText from './components/pantalla_inicial/chatbottext.jsx';
import ChatbotAudioText from './components/pantalla_inicial/ChatbotAudioText.jsx';
import SideBar2 from './components/pantalla_inicial/SideBar2.jsx';
import { Box } from '@mui/material';

function App() {
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY?.trim();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const [open, setOpen] = useState(false);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ redirect_uri: window.location.origin }}
      useRefreshTokens
      cacheLocation="localstorage">
      
      <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <BrowserRouter>
            {/* <Header/> */}
            { <SideBar2 open={open} setOpen={setOpen} /> }
            <Box sx={{ marginLeft : { lg : open ? 30 : 0 }  }} >
            <Routes>
              <Route path="/complete-information" element={<CompleteInformation/>} />
              <Route path="/" element={<Landing/>} />
              <Route path="/*" element={<>
                    <Routes>
                      <Route path="/dashboard" element={<ConversationalChat />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/feedback" element={<Feedback />} />
                      <Route path="/premium" element={<Premium />} />
                      <Route path="/ChatbotAudioText" element={<ChatbotAudioText />} />
                      <Route path="/ChatbotText" element={<ChatbotText />} />
                    </Routes>
                    </>
                }
              />
            </Routes>
            </Box>
          </BrowserRouter>
      </GoogleReCaptchaProvider>
    </Auth0Provider>
  );
}

export default App;
