import React from "react";
import { Box, Container, Grid2 } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { useAuth0 } from "@auth0/auth0-react";
import Hero from "./sections/Hero";
import ChatBox from "./sections/ChatBox";
import Benefits from "./sections/Benefits";
import ContactForm from "./sections/ContactForm";
import Footer from "./sections/Footer";
import LoadingScreen from "../Loading";


const Landing = () => {
  const { isLoading } = useAuth0();

  if (isLoading) 
    return <LoadingScreen />;

  return (
    <Grid2 container display={"flex"} justifyContent={"center"} >
      <Grid2 item >
      <Fade delay={20} direction="left" triggerOnce>
        <Hero />
      </Fade>
      <Fade delay={20} direction="left" triggerOnce>
        <ChatBox />
      </Fade>
      <Fade delay={20} direction="left" triggerOnce>
        <Benefits />
      </Fade>
      <Fade delay={20} direction="left" triggerOnce>
        <ContactForm />
      </Fade>
      <Fade delay={20} direction="left" triggerOnce>
        <Footer />
      </Fade>
      
      </Grid2>
    </Grid2>
  );
};

export default Landing;
