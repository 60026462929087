import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography,MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getUser } from "../services/api";
import { Menu as MenuImg } from "@mui/icons-material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import DatasetIcon from '@mui/icons-material/Dataset';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const TopMenu = () => {

  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const [nombre, setNombre] = useState ("");

  const handleAccountClick = (to) => {
    navigate(to)
  };

  useEffect ( () => {
    getUser(user.email).then ( (response) => {
        setNombre (response.data.nombres.value) 
    })
  },[]); 

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Box border={0}>
          <Typography>
            Hola {nombre}{" "}
          </Typography>
      </Box>
      {/* <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Cuenta">
          <IconButton
            onClick={handleMenuOpen}
            size="small"
            sx={{ ml: 0 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup
            aria-expanded={open ? "true" : undefined}>
            <MenuImg sx={{fontSize: "40px" , color: "black"}}/>
          </IconButton>
        </Tooltip>
      </Box> 
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top"  }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem onClick={ () => handleAccountClick ("/dashboard")}><AccountCircleIcon color="secondary"/> <Typography ml={1}>Inicio</Typography></MenuItem>
        <MenuItem onClick={ () => handleAccountClick ("/profile")}><DatasetIcon color="secondary" /> <Typography ml={1}>Datos Personales</Typography></MenuItem>
        <MenuItem onClick={ () => handleAccountClick ("/feedback")}><FeedbackIcon color="secondary"/><Typography ml={1}>Feedback</Typography></MenuItem>
        <MenuItem onClick={ () => handleAccountClick ("/premium")}><WorkspacePremiumIcon color="secondary"/><Typography ml={1}>Premium</Typography></MenuItem>
        <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } }) }> <LogoutIcon color="secondary"/><Typography ml={1}>Salir</Typography></MenuItem>
      </Menu>
      */}
    </>
  );
};

export default TopMenu;
