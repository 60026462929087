// api.js
import axios from "axios";

// Base URL from .env
const appUrl = process.env.REACT_APP_API_URL;

// Create an axios instance
const api = axios.create({
  baseURL: appUrl,
  // Note: We won’t force "Content-Type: application/json" globally,
  //       because we may need multipart/form-data for file uploads.
  // You can remove or keep these default headers as needed.
  headers: {
    "Access-Control-Allow-Origin": "*",
    // "Content-Type": "application/json", // <-- Remove or comment out if it causes issues
  },
});

// A separate axios instance for emailing
const emailApi = axios.create({
  baseURL: "https://dpv201.pythonanywhere.com",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// ------------------------------
// Email-related functions
// ------------------------------
const sendEmail = async ({ email, nombre, tipo, mensaje, captchaToken }) => {
  try {
    const requestPayload = {
      user_email: email,
      name: nombre,
      role: tipo,
      message: mensaje,
      captchaToken,
    };
    const response = await emailApi.post("/api/email/send", requestPayload);
    return response;
  } catch (error) {
    console.error("Failed to send email:", error.response || error.message);
    throw error;
  }
};

const sendFeedbackEmail = async ({ email, nombre, subject, feedback, captchaToken }) => {
  try {
    const requestPayload = {
      user_email: email,
      name: nombre,
      subject: subject,
      feedback: feedback,
      captchaToken,
    };
    const response = await emailApi.post("/api/email/send-feedback", requestPayload);
    return response;
  } catch (error) {
    console.error("Failed to send feedback email:", error.response || error.message);
    throw error;
  }
};

// ------------------------------
// User-related functions
// ------------------------------
const getAllUserInfo = (email) => {
  try {
    return api.get(`/api/user-info?email=${encodeURIComponent(email)}`);
  } catch (error) {
    console.error("Error getting all user information:", error);
    throw error;
  }
};

const saveOrUpdateUser = async ({ ...data }) => {
  try {
    const response = await api.post("/api/user/", { ...data });
    return response;
  } catch (error) {
    console.error("Failed to update user:", error.response || error.message);
    throw error;
  }
};

const getUser = (email) => {
  try {
    return api.get(`/api/user/?email=${encodeURIComponent(email)}`);
  } catch (error) {
    console.error("Error getting the user:", error);
    throw error;
  }
};

const getComunas = () => {
  try {
    return api.get(`/api/params/comunas`);
  } catch (error) {
    console.error("Error getting comunas:", error);
    throw error;
  }
};

// ------------------------------
// Old function (v1) - If you want to keep it or remove it
// This one specifically calls "/api/chat-bot/query"
// ------------------------------
const sendAudioAndGetStream = (formData) => {
  // If you keep this for the old endpoint,
  // note that it returns a raw Response stream to be consumed.
  return fetch(`${appUrl}/api/chat-bot/query`, {
    method: "POST",
    xhrFields: {
      responseType: "arraybuffer",
    },
    body: formData,
  });
};

// ------------------------------
// New function (v2) to send text/audio to "/api/v2/query_v2"
// ------------------------------
const sendFormDataForQueryV2 = async (formData) => {
  try {
    // Using axios directly:
    //  - We don't manually set "Content-Type": axios will add "multipart/form-data" with boundary.
    const response = await api.post("/api/v2/query_v2", formData, {
      headers: {
        // "Content-Type": "multipart/form-data"  <-- optional, axios will detect if data is FormData
      },
    });
    // The server returns JSON with { user_message_text, assistant_message_text, assistant_audio_url }
    return response.data;
  } catch (error) {
    console.error("Error in sendFormDataForQueryV2:", error.response || error.message);
    throw error;
  }
};

// ------------------------------
// Export all functions
// ------------------------------
export {
  sendEmail,
  sendFeedbackEmail,
  getAllUserInfo,
  saveOrUpdateUser,
  getUser,
  getComunas,
  sendAudioAndGetStream,      // <-- v1 old
  sendFormDataForQueryV2,     // <-- v2 new
};
